@use 'common'
@use 'Splide'

@font-face
	font-family: 'motif'
	src: url('/fonts/motif-bold-webfont.woff2') format('woff2'), url('/fonts/motif-bold-webfont.woff') format('woff')
	font-weight: 700
	font-style: normal

@font-face
	font-family: 'motif'
	src: url('/fonts/motif-regular-webfont.woff2') format('woff2'), url('/fonts/motif-regular-webfont.woff') format('woff')
	font-weight: 400
	font-style: normal

// @font-face
// 	font-family: 'motif'
// 	src: url('/fonts/motiftrial-lightitalic-webfont.woff2') format('woff2'), url('/fonts/motiftrial-lightitalic-webfont.woff') format('woff')
// 	font-weight: 300
// 	font-style: italic

// @font-face
// 	font-family: 'motif'
// 	src: url('/fonts/motiftrial-black-webfont.woff2') format('woff2'), url('/fonts/motiftrial-black-webfont.woff') format('woff')
// 	font-weight: 800
// 	font-style: normal

html,
body
	padding: 0
	margin: 0
	font-family: motif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif
	position: relative
	background-color: common.$lightGrey
	scroll-behavior: smooth

a
	color: inherit
	text-decoration: none

h2, h3
	margin: 0
	margin-top: 0.5rem
	margin-bottom: -1rem

button
	color: #000

#__next
	.maybePaddingRight
		@media (min-width: 1024px)
			padding-right: calc(350px * var(--maybePaddingRight, 0))
		@media (min-width: 1440px)
			padding-right: calc(390px * var(--maybePaddingRight, 0))

*
	box-sizing: border-box
