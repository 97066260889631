$mainColor: #FFB000
$secondaryColor: #EBE6DE
$lightGrey: #F3F3F3
$darkGrey: #646464


$font-serif: motif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif


$pageWidth768: 768px
