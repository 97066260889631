@use 'common'
@import "~@splidejs/splide/dist/css/splide.min.css"

.splide__arrow
	background: none

.splide__arrow:disabled
	opacity: 0
	visibility: hidden


.splide__slide
	display: flex
	justify-content: center
	height: auto

